// import { MoneyV2 } from '@generated/graphql/types'; //TODO: Add MoneyV2 later

export enum GtmEventType {
	CarouselClicked = 'Carousel clicked',
	SearchResultViewed = 'Search Results viewed',
	SearchUsed = 'Search used',
	QuickSearchResultViewed = 'Quick search result viewed',
	ProductListClicked = 'Product List clicked',
	ListDetailsViewed = 'List details viewed',

	CartProductRemoved = 'Cart: Product removed',
	CartViewed = 'Cart: viewed',
	CartVoucherCodeSubmitted = 'Cart: Discount code applied',

	BuyXGetY = 'Buy X Get Y',

	ProductViewed = 'Product: viewed',
	ProductShared = 'Product: shared',
	ProductAddedToCart = 'Product: added to cart',
	ProductFAQ = 'Product: FAQ clicked',
	ProductNotificationSignUp = 'Product: Notification sign up',

	OutboundLinkClicked = 'Outbound link clicked',

	CheckoutStarted = 'Checkout: Started',
	CheckoutStartedShopify = 'Checkout: Started (Shopify AB)',
	CheckoutStepOneCompleted = 'Checkout: Step 1 completed',
	CheckoutStepTwoCompleted = 'Checkout: Step 2 completed',
	CheckoutCompleted = 'Checkout: completed',
	CheckoutCompletedShopify = 'Checkout: completed (Shopify AB)',

	PageNotFound = 'Page not found'
}

export type GtmEvent = { event: GtmEventType };

export interface GtmCartDetails {
	id: string;
	productVariantsCount: number;
	productSkus?: string[];
	productVariantSkus?: string[];
	// totalValue: MoneyV2;
}

export interface ProductDetails {
	product_id: string; // the shopify product id
	product_variant_id_avantrado: string; // variant sku - of Avantrado storage
	product_variant_id_sds: string; // id - variant id of Sprenger own storage
	product_category: string; // breadcrumb nach [slug]
	price_min: number;
	price_max: number;
	availability: 'in_stock' | 'out_of_stock';
	branche: 'dog-sport' | 'equine-sport' | 'boat-sport' | '';
}

export interface ProductVariantDetails {
	variant_id: string; // sku
	product_category: string; // breadcrumb nach [slug]
	price: number;
}

export interface PageNotFound {
	url: string;
}
